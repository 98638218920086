/* GLOBAL STYLES */

@import url('https://fonts.googleapis.com/css2?family=Monda&display=swap');


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Monda", sans-serif;
  font-weight: 500;
  font-size: 20px;
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #F7BD03;
}

::-webkit-scrollbar-thumb {
  background: #F7BD03;
}

/* END GLOBAL STYLES */

/* NAVIGATION STYLES */

#root {
  min-height: 100vh;
}

.topnav { 
  min-height: 5vh;
  background-color: #B5A03D;
  color: #FFF18D;
}

.Footer { 
  min-height: 10vh;
  background-color: #B5A03D;
  color: #FFF18D;
}

body {
  min-height: 85vh;
  background-color: #FFF18D;
}

.nav-container {
  align-items: center;
  padding: 30px 10%;
}

.link-container {
  display: flex;
  justify-content: space-between;
}

.nav-link {
  color: #FFF18D;
}

.team-link {
  color: black;
  vertical-align: middle;
}

.team-link img {
  vertical-align: middle;
  margin-right:10px
}

.nav-left {
  display: flex;
  vertical-align: top;
  float: left;
  padding-right:15px;
}

.nav-right {
  display: flex;
  vertical-align: top;
}

.nav-list {
  display: flex;
  list-style: none;
}

.nav-logo {
  cursor: pointer;
  max-width: 36px;
  max-height: 36px;
}

.landing-logo {
  max-width: 80%;
}

section {
  justify-content: center;
}

.nav-item {
  display: inline-block;
  padding: 0px 15px;
  background-color: #B5A03D;
  font-size: 24px;
}

.nav-item-right {
  padding: 0px;
}

.nav-item a {
  transition: all 0.3 ease 0s;
}

.nav-item a:hover {
  color:#FFDD00
}

.nav-item a:visited {
  color:#FFF18D
}

.footer-container {
  display: flex;
  justify-content: space-between;
  padding: 30px 10%;
}

/* END NAVIGATION STYLES */


/* GENERAL PAGE STYLES */

section {
  display:flex;
  background-color: #FFF18D;
}

/* END GENERAL PAGE STYLES */


/* BEGIN GRID STYLE TEAM PAGE */

.wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  padding: 30px 10%;
}

.bots-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  padding: 30px 10%;
}

.work-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  padding: 30px 10%;
}

.wrapper div {
  padding-top:50px;
  align-self: center;
}

.team-section {
  padding-bottom:30px;
}

.bots-section {
  padding-bottom: 30px;
}

.page-header {
  grid-column: 1/ span 4;
  grid-row: 1;
  text-align: center;
  padding: 30px;
}

.page-header h1 {
  font-size: 36px;
}

.top-left-img {
  grid-column: 1;
  grid-row: 2;
  width: 100%;
  text-align: right;
}

.top-left-text {
  grid-column: 2;
  grid-row: 2;
}

.bot-left-img {
  grid-column: 1;
  grid-row: 3;
  width: 100%;
  text-align: right;
}

.bot-left-text {
  grid-column: 2;
  grid-row: 3;
}

.top-right-img {
  grid-column: 4;
  grid-row: 2;
  width: 100%;
}

.top-right-text {
  grid-column: 3;
  grid-row: 2;
  text-align: right;
}

.bot-right-img {
  grid-column: 4;
  grid-row: 3;
  width: 100%;
}

.bot-right-text {
  grid-column: 3;
  grid-row: 3;
  text-align: right;
}

.team-left-img {
  width:75%;
  border-radius: 50%;
  border: 5px solid #B5A03D;
}

.team-right-img {
  width:75%;
  border-radius: 50%;
  border: 5px solid #B5A03D;
}

.vertical-align {
  vertical-align: middle;
  height: 100%;
  line-height: 200%;
}

.bots-info {
  grid-column: 1 / span 3;
  grid-row: 2;
  text-align: center;
}

.bots-info-row {
  padding: 30px 0px;
}

.bots-info-row h2 {
  font-size: 26px;
}

.bots-info-row  {
  font-size: 26px;
}

.work-info {
  grid-column: 1 / span 4;
  grid-row: 2;
  text-align: center;
}

.work-info-col-1 {
  grid-column: 1 / span 2;
  grid-row: 2;
  text-align: center;
}
.work-info-col-2 {
  grid-column: 3 / span 2;
  grid-row: 2;
  text-align: center;
}

.work-info-row {
  padding: 30px 0px;
}

.work-info-row h2 {
  font-size: 26px;
}

.work-info-row  {
  font-size: 26px;
}

.cosmetic-line {
  padding-top: 50px;
  grid-column: 2;
  grid-row: 3;
  text-align: center;
  border-bottom: #B5A03D 2px solid;
  margin-bottom:100px
}

.package {
  padding: 15px 15px;
  padding-bottom: 100px;
  border: #B5A03D 2px solid;
  margin-bottom: 50px;
}

.package h1 {
  font-size: 36px;
}

.package h2 {
  font-size: 32px;
}

.pro {
  grid-column: 1;
  grid-row: 4;
  text-align: center;
}

.premium {
  grid-column: 2;
  grid-row: 4;
  text-align: center;
}

.bananaz {
  grid-column: 3;
  grid-row: 4;
  text-align: center;
}

.underline-heading {
  display:inline-block;
  text-align: center;
  width:50%;
  border-bottom: #B5A03D 2px solid;
  padding-bottom:10px;
  padding-top:10px;
}

.features-list {
  text-align: left;
  padding-left:45px;
}

.features-header {
  text-align: left;
  padding-left:15px;
}

.specifics {
  grid-column: 3 / span 1;
  grid-row: 5;
  text-align: center;
  border: #B5A03D 2px solid;
  padding: 30px 30px;
  margin-bottom: 50px;
}

.specifics h1 {
  font-size: 36px;
}

.extra-bots {
  grid-column: 1 / span 1;
  grid-row: 5;
  text-align: center;
  border: #B5A03D 2px solid;
  padding: 30px 30px;
  margin-bottom: 50px;
}

.extra-bots h1 {
  font-size: 36px;
}

.custom-bots {
  grid-column: 2 / span 1;
  grid-row: 5;
  text-align: center;
  border: #B5A03D 2px solid;
  padding: 30px 30px;
  margin-bottom: 50px;
}

.custom-bots h1 {
  font-size: 36px;
}

.team-logo {
  width: 30px;
}

.work-1-img {
  grid-column: 1;
  grid-row: 2;
  width: 100%;
  text-align: right;
}

.work-2-img {
  grid-column: 2;
  grid-row: 2;
  width: 100%;
  text-align: right;
}

.work-3-img {
  grid-column: 3;
  grid-row: 2;
  width: 100%;
  text-align: right;
}

.work-4-img {
  grid-column: 4;
  grid-row: 2;
  width: 100%;
  text-align: right;
}

.right-align {
  float:right !important;
  margin-right: 10% !important;
}

/* Add a black background color to the top navigation */
.topnav {
  background-color: #B5A03D;
  overflow: hidden;
  padding-left: 10%;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  display: block;
  color: #FFF18D;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 24px;
}


/* Hide the link that should open and close the topnav on small screens */
.topnav .icon {
  display: none;
}

/* Dropdown container - needed to position the dropdown content */
.dropdown {
  float: left;
  overflow: hidden;
}

/* Style the dropdown button to fit inside the topnav */
.dropdown .dropbtn {
  font-size: 17px;
  border: none;
  outline: none;
  color: #FFF18D;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

/* Style the dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #FFF18D;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Style the links inside the dropdown */
.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* Add a dark background on topnav links and the dropdown button on hover */
.topnav a:hover, .dropdown:hover .dropbtn {
  background-color: #6A6239;
  color: #FFF18D;
}

/* Add a grey background to dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
  color: black;
}

/* Show the dropdown menu when the user moves the mouse over the dropdown button */
.dropdown:hover .dropdown-content {
  display: block;
}

/* When the screen is less than 600 pixels wide, hide all links, except for the first one ("Home"). Show the link that contains should open and close the topnav (.icon) */
@media screen and (max-width: 600px) {
  .topnav a:not(:first-child), .dropdown .dropbtn {
    display: none;
  }
  .topnav a.icon {
    float: right;
    display: block;
  }

  .right-align {
    margin-right: 0% !important;
    float: none !important;
  }
}

/* The "responsive" class is added to the topnav with JavaScript when the user clicks on the icon. This class makes the topnav look good on small screens (display the links vertically instead of horizontally) */
@media screen and (max-width: 600px) {
  .topnav.responsive {position: relative;}
  .topnav.responsive a.icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
  .topnav.responsive .dropdown {float: none;}
  .topnav.responsive .dropdown-content {position: relative;}
  .topnav.responsive .dropdown .dropbtn {
    display: block;
    width: 100%;
    text-align: left;
  }
  .right-align {
    margin-right: 0% !important;
    float: none !important;
  }
}

@media only screen and (max-width: 600px) {

  .work-1-img {
    grid-column: 1 / span 2;
    grid-row: 2;
    width: 100%;
    text-align: right;
  }
  
  .work-2-img {
    grid-column: 3 / span 2;
    grid-row: 2;
    width: 100%;
    text-align: right;
  }
  
  .work-3-img {
    grid-column: 1 / span 2;
    grid-row: 3;
    width: 100%;
    text-align: right;
  }
  
  .work-4-img {
    grid-column: 3 / span 2;
    grid-row: 3;
    width: 100%;
    text-align: right;
  }

  .cosmetic-line {
    padding-top: 50px;
    grid-column: 1 / span 3;
    grid-row: 3;
    text-align: center;
    border-bottom: #B5A03D 2px solid;
    margin-bottom:100px;
  }

  .pro {
    grid-column: 1 / span 3;
    grid-row: 4;
    text-align: center;
  }
  
  .premium {
    grid-column: 1 / span 3;
    grid-row: 5;
    text-align: center;
  }
  
  .bananaz {
    grid-column: 1 / span 3;
    grid-row: 6;
    text-align: center;
  }
  
  .specifics {
    grid-column: 1 / span 3;
    grid-row: 7;
    word-wrap: break-word;
  }

  .extra-bots {
    grid-column: 1 / span 3;
    grid-row: 8;
    word-wrap: break-word;
  }

  .custom-bots {
    grid-column: 1 / span 3;
    grid-row: 9;
    word-wrap: break-word;
  }
  
  .vertical-align {
    vertical-align: top;
    line-height: normal;
  }

  .top-left-img {
    grid-column: 1 / span 2;
    grid-row: 2;
    text-align: center;
  }
  
  .top-left-text {
    grid-column: 3 / span 2;
    grid-row: 2;
  }
  
  .bot-left-img {
    grid-column: 3 / span 2;
    grid-row: 3;
    padding-top:25px;
    text-align: center;
  }
  
  .bot-left-text {
    grid-column: 1 / span 2;
    grid-row: 3;
    padding-top:25px;
    text-align: right;
  }
  
  .top-right-img {
    grid-column: 1 / span 2;
    grid-row: 4;
    padding-top:25px;
    text-align: center;
  }
  
  .top-right-text {
    grid-column: 3 / span 2;
    grid-row: 4;
    text-align: left;
    padding-top:25px;
  }
  
  .bot-right-img {
    grid-column: 3/ span 2;
    grid-row: 5;
    padding-top:25px;
    padding-bottom:25px;
    text-align: center;
  }
  
  .bot-right-text {
    grid-column: 1 / span 2;
    grid-row: 5;
    text-align: right;
    padding-top:25px;
    padding-bottom:25px;
    text-align: right;
  }

  .team-left-img {
    width: 100%;
  }
}

/* END GRID STYLE TEAM PAGE */

